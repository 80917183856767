import React from 'react'
import './footer.scss'
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="loginfooter">
        <div className="custom-container">
          <div className="innerloginfooter">
            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="23" viewBox="0 0 74 23" fill="none">
              <path d="M5.57342 0L0 6.02772V9.9373L12.2815 22.2043L24.5575 9.94388V8.36514H9.56228L10.6015 11.6246H17.5784L12.2804 16.9188L3.86485 8.50986L8.13792 3.89423H16.4229L18.2489 5.86875H23.8684V5.2833L18.983 0H5.57342Z" fill="#E1EFF5" />
              <path d="M35.2796 11.575V9.90421H40.154V15.7903H38.6518L38.3606 14.3495C37.5635 15.4838 36.3832 16.0356 34.8044 16.0356C33.2256 16.0356 31.9686 15.5144 31.0183 14.4721C30.0679 13.4297 29.5927 12.0502 29.5927 10.3334C29.5927 8.61662 30.0679 7.23706 31.0489 6.1794C32.0146 5.15239 33.3328 4.63123 34.9883 4.63123C36.3526 4.63123 37.4869 4.98378 38.4066 5.67356C39.2956 6.37867 39.8474 7.3137 40.0314 8.50932H37.9007C37.7474 7.86552 37.4102 7.35969 36.889 6.9918C36.3679 6.62392 35.7394 6.43998 35.0036 6.43998C33.992 6.43998 33.2102 6.79253 32.6277 7.48231C32.0453 8.18742 31.754 9.13778 31.754 10.3334C31.754 11.5443 32.0453 12.4947 32.6431 13.1845C33.2256 13.8896 34.038 14.2268 35.0803 14.2268C35.8927 14.2268 36.5978 13.9816 37.1803 13.4911C37.7628 13.0005 38.0693 12.3721 38.1306 11.575H35.2796Z" fill="#E1EFF5" />
              <path d="M50.1274 4.87648V6.73122H44.1493V9.3064H49.5603V11.0845H44.1493V13.9356H50.0968V15.7903H42.08V4.87648H50.1274Z" fill="#E1EFF5" />
              <path d="M58.5028 10.0728L60.3269 4.87648H63.3313V15.7903H61.3539V12.4641C61.3539 10.4254 61.3693 8.61662 61.4153 7.05312C61.1853 7.81954 60.9094 8.6626 60.5875 9.56698L58.3802 15.7903H56.6788L54.4561 9.56698C54.0576 8.43268 53.797 7.60494 53.6437 7.08377C53.6744 8.64727 53.7051 10.4407 53.7051 12.4641V15.7903H51.7124V4.87648H54.7167L56.6021 10.1801C56.7248 10.548 57.0313 11.529 57.5372 13.1385C57.8591 12.0042 58.181 10.9925 58.5028 10.0728Z" fill="#E1EFF5" />
              <path d="M69.5853 16.0356C68.0984 16.0356 66.9488 15.7137 66.1364 15.0392C65.324 14.3801 64.9101 13.4297 64.9101 12.1881H66.9488C66.9488 12.9699 67.1787 13.5217 67.6386 13.8589C68.0984 14.1962 68.7576 14.3648 69.6466 14.3648C70.3517 14.3648 70.9035 14.2422 71.2868 13.9662C71.6546 13.6903 71.8539 13.3071 71.8539 12.7859C71.8539 12.3568 71.6853 12.0349 71.3634 11.8049C71.0415 11.5903 70.459 11.3911 69.616 11.2071L68.6963 11.0232C66.351 10.594 65.186 9.49034 65.186 7.71224C65.186 6.77721 65.5386 6.02611 66.259 5.47429C66.9641 4.92247 67.9605 4.63123 69.2327 4.63123C70.6736 4.63123 71.7619 4.93779 72.4977 5.55093C73.2335 6.16407 73.632 7.02246 73.678 8.12611H71.67C71.624 7.51297 71.4094 7.05312 71.0415 6.74655C70.6736 6.45531 70.0758 6.30202 69.2634 6.30202C68.6196 6.30202 68.1444 6.42465 67.8072 6.65458C67.47 6.89983 67.3014 7.22173 67.3014 7.65093C67.3014 8.09545 67.4547 8.43268 67.7612 8.6626C68.0678 8.90786 68.6349 9.10713 69.432 9.26041L70.3824 9.44435C71.6546 9.70494 72.5743 10.0881 73.1415 10.594C73.7087 11.0998 73.9999 11.7896 73.9999 12.6787C73.9999 13.7516 73.6014 14.5794 72.8349 15.1619C72.0532 15.7443 70.9649 16.0356 69.5853 16.0356Z" fill="#E1EFF5" />
            </svg>
            <p className="footerpara">©{currentYear} GEMS. All rights reserved</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
