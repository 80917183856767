import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Environment from "../../../utils/Environment";
import axios from "axios";
import { toast } from "react-toastify";
import Footer from "../footer/Footer";

const Banner = () => {
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [checkboxError, setCheckboxError] = useState(""); // State for checkbox error
  const api_url = Environment.api_url;

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const userSign = (e) => {
    let hasError = false;
    if (email.length === 0) {
      setEmailErrorRegister("Email is Required");
      hasError = true;
    } else if (!isValidEmail(email)) {
      setEmailErrorRegister("Email is invalid");
      hasError = true;
    } else {
      setEmailErrorRegister("");
    }

    if (!isChecked) {
      setCheckboxError("You must agree to the terms and conditions.");
      hasError = true;
    } else {
      setCheckboxError("");
    }

    if (!hasError) {
      var data = JSON.stringify({
        email: email,
      });
      var config = {
        method: "post",
        url: `${api_url}/temp/gems-trade`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          handleShow();
          setEmail("");
          setEmailErrorRegister("");
          setCheckboxError(""); // Clear checkbox error on successful submission
          setIsChecked(false); // Reset checkbox
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="main-banner">
        <img
          src="/assets/bannershadow.png"
          alt="img"
          className="img-fluid banner-shadow"
        />
        <video
          className="main-banner-video"
          muted="muted"
          playsinline="playsinline"
          autoPlay
          loop
          width="100%"
          id="myVideo"
        >
          <source src="/assets/new-banner-video.mp4" type="video/mp4" />
        </video>
        <div className="custom-container">
          <div className="main-content">
            <img src="\logo.svg" alt="img" className="img-fluid banner-logo" />
            <h4>launching soon</h4>
            <h6>
              A faster, better and more secure crypto exchange with all the
              features you need to trade your crypto assets
            </h6>
            <p>
              Sign up today and be the first to know about the latest updates
            </p>
            <div className="option-field">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Enter your email address"
              />

              <a onClick={() => userSign()} href="#">
                SUBMIT
              </a>
            </div>
            {emailerrorregister ? (
              <p className="text-danger mt-2 mb-0">{emailerrorregister}</p>
            ) : null}
             {checkboxError && !emailerrorregister ? (
              <p className="text-danger mt-2 mb-0">{checkboxError}</p>
            ) : null}
            <div className="custom-checkbox">
              <div class="form-group">
                <input type="checkbox" id="html" checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)} />
                <label for="html"></label>
              </div>
              <h5 className="checkboxpara">I agree to receive promotional emails and important announcements from gems.</h5>
            </div>
           
          </div>
        </div>
        <Footer />
      </section>

      <Modal
        className="success-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Signed successfully!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src="\assets\check.svg" alt="img" className="img-fluid" />
          <h6>
            Thank you for signing up! <br />
            We will keep you posted
          </h6>
          <a onClick={handleClose}>CLOSE</a>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
