import React, { useEffect, useState } from "react";
import "./App.scss";
import Landing from "./components/landing/Landing.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/landing/footer/Footer.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
        <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      style={{ fontSize: '16px', zIndex: "9999999999999999" }} 
      draggable
      pauseOnHover
      theme="colored"
    />

      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Landing />}
          />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
